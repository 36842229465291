<template>
  <div id="lawyers" style="padding-top: 126px">
    <app-header />
    <div class="current_position w">
      <div class="current_position_text">当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="(item, i) in $route.meta.breadcrumb"
          :to="item.path ? { path: item.path } : ''"
          :key="i"
          >{{ item.name }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <!-- 服务机构 -->
    <div style="margin-bottom: 20px" class="institution_list w">
      <div class="img">
        <img src="../../assets/image/组织.png" alt="" />
      </div>
      律师
    </div>
    <!-- 内容 -->
    <div class="Personal w">
      <div
        class="left"
        v-for="item in lawyerList"
        :key="item.id"
        @click="$router.push(`/lawyersDetails/${item.id}`)"
      >
        <div class="img">
          <img class="pic" :src="item.lawyer_img" alt="" />
        </div>
        <div class="introduce">
          <div class="th">{{ item.full_name }}</div>
          <div class="tb">{{ item.work_unit }}</div>
          <div class="tb tb2">
            执业证号:
            <div>{{ item.license_no }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[12, 24, 36, 48]"
        :page-size="+Page.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Page.count"
      >
      </el-pagination>
    </div>
    <app-footer />
  </div>
</template>

<script>
import Header from '../../components/Header/header.vue';
import Footer from '../../components/Footer/footer.vue';
import { lawyer_list } from '../../api/policies.js';
export default {
  data() {
    return {
      lawyerList: [], //律师列表
      Page: {
        page: '1', //页数
        limit: '12', //条数
        keyword: '',
      },
    };
  },
  created() {
    this.lawyer_list();
  },
  methods: {
    handleSizeChange(v) {
      console.log(v);
      this.Page.limit = v;
      this.lawyer_list('1', this.Page.limit);
    },
    handleCurrentChange(v) {
      // console.log(v);
      this.Page.page = v;
      this.lawyer_list(v, this.Page.limit);
    },
    async lawyer_list(page = '1', limit = '12') {
      const parameter = {
        page,
        limit,
        keyword: '',
      };
      const res = await lawyer_list(parameter);
      let count = res.count;
      this.Page = { ...this.Page, count };
      this.lawyerList = res.result;
    },
  },
  components: { 'app-header': Header, 'app-footer': Footer },
};
</script>

<style scoped>
.introduce {
  width: 147px;
  word-break: break-all;
}
#lawyers .block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 130px;
  margin-top: 50px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* 	overflow: hidden;
     */
}
.w {
  width: 1520px;
  margin: 0 auto;
}
.current_position {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.current_position_text {
  font-size: 14px;
  color: #838383;
}
.d8f {
  color: #0d8eff;
}
.institution_list {
  display: flex;
  align-items: center;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.institution_list .img {
  width: 18px;
  height: 18px;
  /* border: 1px dashed #666; */
  margin-right: 20px;
}
.institution_list span img {
  width: 100%;
  height: 100%;
}
.Personal {
  display: grid;
  gap: 0px 37px;
  grid-template-columns: repeat(4, 1fr);
}
.Personal .left {
  display: flex;
  width: 355px;
  height: 220px;
  border: 1px solid #e8e8e8;
  padding: 20px;
  margin-bottom: 30px;
}
.Personal .left .img {
  margin-right: 20px;
  width: 150px;
  height: 180px;
  object-fit: cover;
  overflow: hidden;
}
.Personal .left .img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.introduce .th {
  height: 33.33%;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #333333;
}
.introduce .tb {
  height: 33.33%;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.introduce .tb2 {
  color: #595959;
}
.pic {
  transition: all 1s;
}
.pic:hover {
  transform: scale(1.5);
  /* transition: all 1s; */
}
</style>
